<template>
  <div class="d-flex justify-content-center align-items-center vh-100">
    <div class="text-center">
      <h1 class="mt-5">Welcome to your HR AI Services</h1>
      <h2 class="mb-5">How can I help you?</h2>
      <router-link to="/onboarding" class="btn btn-primary">On Boarding Solutions</router-link>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home'
}
</script>

<style>
.vh-100 {
  height: 100vh;
}
</style>
