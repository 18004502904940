import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/Home.vue';
import OnBoarding from '../components/OnBoarding.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/onboarding',
        name: 'OnBoarding',
        component: OnBoarding,
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
