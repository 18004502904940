<template>
  <div class="container-fluid">
    <h1 class="text-center my-4">On Boarding Services</h1>
    <div class="row">
      <div class="col-md-3 menu">
        <h2 class="text-center mb-5">Document Processing</h2>
        <label for="documentType">Type of document:</label>
        <select id="documentType" class="custom-select" v-model="selectedForm" @change="handleFormSelection">
          <option disabled value="">Please select one</option>
          <option>Employment Contract</option>
          <option>Joining Form</option>
          <option>Non disclosure Agreement</option>
          <option>Asset Handover Form</option>
        </select>
      </div>
      <div class="col-md-9 content-area">
        <div class="upload-section" v-if="selectedForm === 'Employment Contract'">
          <input type="file" @change="handleFileUpload" style="display: none;" ref="fileInput">
          <div class="drag-drop-area btn btn-outline-secondary w-100 mb-3" @click="triggerFileInput" @drop.prevent="handleFileDrop" @dragover.prevent>
            <template v-if="fileName">
              {{ fileName }}
            </template>
            <template v-else>
              Drag and drop a file here or click to select a file
            </template>
          </div>
          <form v-if="fileUploaded" @submit.prevent="submitForm">
            <div class="form-group">
              <label for="lastName">Last Name :</label>
              <input id="lastName" type="text" class="form-control" v-model="formData.lastName" required>
            </div>
            <div class="form-group">
              <label for="middleName">Middle Name (Optional) :</label>
              <input id="middleName" type="text" class="form-control" v-model="formData.middleName">
            </div>
            <div class="form-group">
              <label for="firstName">First Name :</label>
              <input id="firstName" type="text" class="form-control" v-model="formData.firstName" required>
            </div>
            <div class="form-group">
              <label for="nationality">Nationality :</label>
              <select id="nationality" class="form-control" v-model="formData.nationality" required>
                <option disabled value="">Please select one</option>
                <option value="USA">United States</option>
                <option value="Canada">Canada</option>
                <option value="etc">...</option>
              </select>
            </div>
            <div class="form-group">
              <label for="nationalId">National ID :</label>
              <input id="nationalId" type="number" class="form-control" v-model="formData.nationalId">
            </div>
            <div class="form-group">
              <label for="passportNumber">Passport Number :</label>
              <input id="passportNumber" type="number" class="form-control" v-model="formData.passportNumber">
            </div>
            <div class="form-group">
              <label for="dateOfJoining">Date of Joining :</label>
              <input id="dateOfJoining" type="date" class="form-control" v-model="formData.dateOfJoining" required>
            </div>
            <div class="form-group d-flex align-items-center justify-content-start">
              <label for="basicSalary" class="mr-2">Basic Salary :</label>
              <div class="d-flex align-items-center mr-2">
                <input id="basicSalary" type="number" class="form-control" style="width: 500px;" v-model="formData.basicSalary" required>
                <select class="custom-select" style="width: 100px; margin-left: 5px;" v-model="formData.basicSalaryCurrency">
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                </select>
              </div>
            </div>
            <div class="form-group d-flex align-items-center justify-content-start">
              <label for="allowances" class="mr-2">Allowances :</label>
              <div class="d-flex align-items-center">
                <input id="allowances" type="number" class="form-control" style="width: 500px;" v-model="formData.allowances" required>
                <select class="custom-select" style="width: 100px; margin-left: 5px;" v-model="formData.allowancesCurrency">
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                </select>
              </div>
            </div>
            <button type="submit" class="btn btn-primary" :disabled="!formIsValid">Submit</button>
          </form>
          <div v-if="submitted" class="download-options mt-3 text-center">
            <a :href="downloadLink" download class="btn btn-success mx-2">Download Original</a>
            <button @click="convertToPDF" class="btn btn-info mx-2">Download as PDF</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnBoarding',
  data() {
    return {
      selectedForm: '',
      fileUploaded: false,
      submitted: false,
      downloadLink: '',
      fileName: '',
      formData: {
        lastName: '',
        middleName: '',
        firstName: '',
        nationality: '',
        nationalId: '',
        passportNumber: '',
        dateOfJoining: '',
        basicSalary: '',
        allowances: '',
      basicSalaryCurrency: 'USD',
      allowancesCurrency: 'USD'
      }
    };
  },
  computed: {
    formIsValid() {
        const idOrPassportValid = this.formData.nationalId || this.formData.passportNumber;
        return this.formData.lastName && this.formData.firstName &&
            this.formData.nationality && idOrPassportValid &&
            this.formData.dateOfJoining && this.formData.basicSalary &&
            this.formData.allowances && !isNaN(this.formData.basicSalary) &&
            !isNaN(this.formData.allowances);
      }
  },
  methods: {
    handleFormSelection() {
      this.fileUploaded = false;
      this.submitted = false;
      this.fileName = '';
      this.formData = {
        lastName: '',
        middleName: '',
        firstName: '',
        nationality: '',
        nationalId: '',
        passportNumber: '',
        dateOfJoining: '',
        basicSalary: '',
        allowances: ''
      };
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.fileUploaded = true;
        this.fileName = file.name;
        this.downloadLink = URL.createObjectURL(file);
      }
    },
    handleFileDrop(event) {
      const file = event.dataTransfer.files[0];
      this.$refs.fileInput.files = event.dataTransfer.files;
      if (file) {
        this.fileUploaded = true;
        this.fileName = file.name;
        this.downloadLink = URL.createObjectURL(file);
      }
    },
    submitForm() {
      this.submitted = true;
      console.log('Form submitted:', this.formData);
    },
    convertToPDF() {
      // Placeholder for PDF conversion logic
      console.log('Conversion to PDF is not implemented.');
    }
  }
}
</script>

<style scoped>
.menu {
  background-color: #f4f4f4;
  padding: 20px;
  height: 100vh;
}

.content-area {
  margin-top: 20px;
  padding: 20px;
}

.upload-section {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
}

.drag-drop-area {
  cursor: pointer;
  text-align: center;
  padding: 20px;
  border: 2px dashed #ccc;
  transition: background-color 0.3s;
}

.drag-drop-area:hover {
  background-color: #f8f9fa;
}

.download-options {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.currency {
  margin-left: 5px;
}
</style>

